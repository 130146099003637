<template>
  <section class="news">
    <div class="tnf-breadcrumbs">
      <v-container class="hidden-sm-and-down">
        <v-breadcrumbs :items="breadcrumbs" divider=" 〉 "></v-breadcrumbs>
      </v-container>
    </div>
    <v-container  grid-list-md text-center v-if="detail">
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 class="text-left">
            <h3 class="news-title">{{detail.title}}</h3>
            <h6 class="news-date">发布时间: {{ detail.create_time }}</h6>
            <div  v-html="detail.content" class="tnf-news-content">
              {{detail.content}}
            </div>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    breadcrumbs: [],
    pageId: 4,
    detail: null,
    innerWidth: window.innerWidth,
    news: {
      'introduction': null
    }
  }),
  created () {
    this.getNew(this.$route.params)
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    'detail': {
      handler: function () {
        this.$nextTick(function () {
          if (this.innerWidth < 550) {
            let imgs = document.querySelectorAll('.tnf-news-content img')
            imgs.forEach((img, i) => {
              img.classList.add('full-img')
            })
          } else {
            document.querySelector('.tnf-news-content').style.maxWidth = '550px'
          }
          let imgs = document.querySelectorAll('.tnf-news-content img')
          imgs.forEach((img, i) => {
            img.parentNode.classList.add('tnf-no-indent-p')
          })
          let ps = document.querySelectorAll('.tnf-news-content strong')
          ps.forEach((p, i) => {
            p.parentNode.classList.add('tnf-no-indent-p')
          })
        })
      }
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getNew (param) {
      this.https.get('new', param).then(response => {
        if (response.code === 0) {
          this.detail = response.data.detail
          document.title = this.detail.title
          this.breadcrumbs = [
            { text: '首页', disabled: false, href: '/' },
            { text: '新闻资讯', disabled: false, href: '/news' },
            { text: this.detail.title, disabled: true }
          ]
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '../css/new.css';
</style>
